import React, { useContext, useEffect, useState } from "react";
import "../../../styles/dashboard.css";
import ApplicationData from "../applicationData";
import { CiSearch } from "react-icons/ci";
import ApplicationLinkModal from "../../../../utils/modals/ApplicationLinkModal";
import axios from "../../../../api/axios";
import Cookies from "universal-cookie";
import { GlobalContext } from "../../../../context/GlobalContext";
import { useLocation, useNavigate } from "react-router-dom";
import QueryString from "query-string";
import LoadingBar from "react-top-loading-bar";
import image from "../../../../Images/Frame-31.webp";
import { getBaseStyle, getHoverStyle } from "../../../../utils/styles";
const cookies = new Cookies();

const ApplictionLink = () => {
  const [progress, setProgress] = useState(30);
  const [showPage, setShowPage] = useState(false);
  const [copied, setCopied] = useState(false);
  const location = useLocation();
  const [showModal, setShowModal] = useState();
  const [applicationLink, setApplicationLink] = useState([]);
  const search = QueryString.parse(location.search)?.search;
  const [paramSearch, setParamSearch] = useState(true);
  const [searchString, setSearchString] = useState(search ? search : "");
  const [buttonHover, setButtonHover] = useState(false);
  const [searchHover, setSearchHover] = useState(false);
  const [marketingLink, setMarketingLink] = useState(null);
  const { state, dispatch } = useContext(GlobalContext);
  const baseColor = state?.user?.themeColor;
  const baseStyle = getBaseStyle(baseColor);
  const hoverStyle = getHoverStyle(baseColor);
  const navigate = useNavigate();
  const updateQueryParams = (newParams) => {
    const currentParams = QueryString.parse(window.location.search);

    if (!newParams.hasOwnProperty("search")) {
      delete currentParams.search;
    }

    const updatedParams = { ...currentParams, ...newParams };

    const updatedQueryString = QueryString.stringify(updatedParams);

    // Update the URL with the new query string
    window.history.pushState({}, "", `?${updatedQueryString}`);

    // You can also use React Router navigation if you're using it
    // router.navigate(`?${updatedQueryString}`);
  };
  const GetApplicationLinkData = () => {
    axios
      .get(`lender/applicationlink/?search=${searchString}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${cookies.get("access")}`,
        },
        withCredentials: true,
      })
      .then((res) => {
        setProgress(100);
        setShowPage(true);
        setApplicationLink(res?.data?.main_applications);
        if (res?.data?.marketing_link) {
          setMarketingLink(res?.data?.marketing_link?.link);
        }
        if (searchString !== "") {
          updateQueryParams({ search: searchString });
        } else {
          updateQueryParams({});
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          console.log(err);
          window.localStorage.setItem("isLoggedIn", "false");
          navigate("/login");
        }
      });
  };
  useEffect(() => {
    if (state.refreshApplications) {
      axios
        .get(`lender/applicationlink/?search=${searchString}`, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${cookies.get("access")}`,
          },
          withCredentials: true,
        })
        .then((res) => {
          dispatch({
            type: "REFRESH_APPLICATIONS",
            payload: false,
          });
          setProgress(100);
          setShowPage(true);
          setApplicationLink(res?.data?.main_applications);
          if (res?.data?.marketing_link) {
            setMarketingLink(res?.data?.marketing_link?.link);
          }
          if (searchString !== "") {
            updateQueryParams({ search: searchString });
          } else {
            updateQueryParams({});
          }
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            console.log(err);
            window.localStorage.setItem("isLoggedIn", "false");
            navigate("/login");
          }
        });
    }
  }, [state.refreshApplications]);
  useEffect(() => {
    if (searchString === "") {
      GetApplicationLinkData();
      setParamSearch(false);
    } else if (search !== "" && paramSearch) {
      GetApplicationLinkData();
      setParamSearch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.refreshApplicationLink, searchString]);
  const copyMarketingLinkFunc = async () => {
    if (marketingLink) {
      const linkToCopy = `${process.env.REACT_APP_BASE_FRONT_END_URL}application/${marketingLink}`;
      await navigator.clipboard.writeText(linkToCopy);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  };
  return (
    <>
      <ApplicationLinkModal showModal={showModal} setShowModal={setShowModal} />
      <LoadingBar
        color="rgba(0, 148, 255, 1)"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      {showPage && (
        <div className="row mt-4 mb-4">
          <div className="col-lg-12 col-md-12">
            <div className="colorr shadow p-3 rounded-4 notCompleted-height">
              <div className="app-serach-field">
                <div>
                  <h4>Application Links</h4>
                </div>
                <div className="ms-auto">
                  <div className="icon-field">
                    <CiSearch className="ms-2 icon-search" />
                    <input
                      className="search-here input-filed search-bar-field"
                      type="text"
                      name={searchString}
                      placeholder="Search here..."
                      value={searchString}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          GetApplicationLinkData();
                        }
                      }}
                      onChange={(e) => {
                        setSearchString(e.target.value);
                      }}
                    />
                    <input
                      type="button"
                      onClick={GetApplicationLinkData}
                      className="search-bar-btn"
                      onMouseEnter={() => setSearchHover(true)}
                      onMouseLeave={() => setSearchHover(false)}
                      name=""
                      value="Search"
                      style={
                        state?.user?.role !== "Staff"
                          ? {}
                          : searchHover
                          ? hoverStyle
                          : baseStyle
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-lg-12 col-md-12">
                  <button
                    className={`btn btn-warning generate-link-btn`}
                    onClick={() => setShowModal(true)}
                    onMouseEnter={() => setButtonHover(true)}
                    onMouseLeave={() => setButtonHover(false)}
                    style={
                      state?.user?.role !== "Staff"
                        ? { color: "white" }
                        : buttonHover
                        ? hoverStyle
                        : baseStyle
                    }
                  >
                    Generate new application link
                  </button>
                  {marketingLink && (
                    <button
                      className={`btn btn-warning generate-link-btn`}
                      onClick={copyMarketingLinkFunc}
                      // onMouseEnter={() => setButtonHover(true)}
                      // onMouseLeave={() => setButtonHover(false)}
                      style={
                        state?.user?.role !== "Staff"
                          ? {
                              backgroundColor: "#0094FF",
                              color: "white",
                              borderColor: "transparent",
                              marginLeft: window.screen.width > 500 && "10px",
                            }
                          : {
                              backgroundColor: baseColor,
                              color: "white",
                              borderColor: "transparent",
                              marginLeft: "10px",
                            }
                      }
                    >
                      {copied ? "Copied!" : "Copy marketing application link"}
                    </button>
                  )}

                  <div className="App mt-3">
                    {applicationLink?.length > 0 ? (
                      <ApplicationData data2={applicationLink} />
                    ) : (
                      <div className="no-data">
                        <img src={image} alt="logo" className="data-display" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ApplictionLink;
