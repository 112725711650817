import "../styles/question.css";
import img1 from "./../../Images/logo2.webp";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Fab, Step, StepLabel, Stepper } from "@mui/material";
import { Component } from "./dynamiccomponents";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { useParams } from "react-router";
import axios from "../../api/axios";
import Missing from "../../Missing";
import { GlobalContext } from "../../context/GlobalContext";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Spinner from "react-bootstrap/Spinner";

function Question() {
  const [component, setComponent] = useState("GetStarted");
  const [showLoader, setShowLoader] = useState(true);
  const { state, dispatch } = useContext(GlobalContext);
  const [activeStep, setActiveStep] = useState(1);
  const [pageNotFound, setPageNotFound] = useState(false);
  // const { link1 } = useParams();
  // const { link, cost, machine_model, machine_year, machine_make } = useParams();
  const { pathname, search } = useLocation();
  const params = new URLSearchParams(search);
  const BOOM_AND_BUCKET_DOMAIN = "www.boomandbucket.com";
  const CRANE_MARKET_DOMAIN = "cranemarket.com";
  const IRON_POWER_DOMAIN = "ironpowerindustries.com";

  let link = null;
  let cost = null;
  let machine_model = null;
  let machine_year = null;
  let machine_make = null;

  console.log("state", state);
  console.log("questions", state?.questions);

  const pathSegments = pathname.split("/");
  if (pathSegments.length === 3) {
    link = pathSegments[2];
  } else if (pathSegments.length === 4 && pathSegments[3] === "") {
    link = pathSegments[2];
  }

  if (params.has("cost")) {
    cost = +params.get("cost");
  }
  if (params.has("machine_model")) {
    machine_model = params.get("machine_model");
  }
  if (params.has("machine_year")) {
    machine_year = params.get("machine_year");
  }
  if (params.has("machine_make")) {
    machine_make = params.get("machine_make");
  }

  const [progress, setProgress] = useState(0);

  var paramsArray = [machine_model, machine_make, machine_year].filter(
    (value) => {
      return value !== undefined && value !== null && value !== "";
    }
  );

  var param = paramsArray.join("-");

  const {
    referrerUrl,
    referrerEquipmentDescription,
    referrerEquipmentCost,
    referrerEquipmentImageUrl,
    referrerEquipmentMonthlyCost,
  } = state?.questions;

  useEffect(() => {
    const referrer = document.referrer;
    console.log("Referrer", referrer);
    const obj = { ...state.questions };
    // This will get the url if the user is coming from external website
    if (referrer) {
      let domainName = null;
      let apiBaseUrl = null;
      try {
        const referrerURL = new URL(referrer);
        domainName = referrerURL.hostname;
      } catch (err) {}
      if (
        domainName === BOOM_AND_BUCKET_DOMAIN ||
        domainName === CRANE_MARKET_DOMAIN ||
        domainName === IRON_POWER_DOMAIN
      ) {
        obj.referrerUrl = referrer;
        dispatch({
          type: "QUESTIONS",
          payload: obj,
        });
      }
      if (domainName === BOOM_AND_BUCKET_DOMAIN) {
        apiBaseUrl = "lender/scrape/boom-and-bucket/";
      } else if (domainName === CRANE_MARKET_DOMAIN) {
        apiBaseUrl = "lender/scrape/crane-market/";
      } else {
        apiBaseUrl = "lender/scrape/iron-power/";
      }
      axios
        .post(
          apiBaseUrl,
          { url: referrer },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
          }
        )
        .then((res) => {
          const response = res?.data;
          obj.referrerEquipmentDescription = response?.marketing_equipment_name;
          obj.referrerEquipmentCost = response?.marketing_equipment_price;
          obj.referrerEquipmentImageUrl =
            response?.marketing_equipment_image_url;
          obj.referrerEquipmentMonthlyCost =
            response?.marketing_equipment_monthly_cost;
          dispatch({
            type: "QUESTIONS",
            payload: obj,
          });
        })
        .catch((err) => {})
        .finally(() => {
          setShowLoader(false);
        });
    } else {
      setShowLoader(false);
    }
  }, []);

  useEffect(() => {
    const obj = { ...state.questions };
    axios
      .post(
        "lender/validate-slug/",
        { link: link, equipment_price: cost, equipment_name: param },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.data.link_type === "Marketing") {
          const updatedUser = {
            ...state.user,
            logo: res?.data?.company_logo,
            themeColor: res?.data?.theme_color,
          };
          dispatch({
            type: "USER_INFO",
            payload: updatedUser,
          });
          obj.isMarketingLink = true;
          dispatch({
            type: "QUESTIONS",
            payload: obj,
          });
          dispatch({
            type: "INFORMATION_SLUG",
            payload: res?.data?.slug,
          });
          dispatch({
            type: "PERSONAL_FLOW",
            payload: res?.data?.is_personal_flow,
          });
        } else {
          const updatedUser = {
            ...state.user,
            logo: res?.data?.company_logo,
            themeColor: res?.data?.theme_color,
            isDefault: res?.data?.is_default,
          };
          dispatch({ type: "USER_INFO", payload: updatedUser });
          dispatch({
            type: "INFORMATION_SLUG",
            payload: res?.data?.slug,
          });
          dispatch({
            type: "PERSONAL_FLOW",
            payload: res?.data?.is_personal_flow,
          });
        }
      })
      .catch((err) => {
        console.log(err, "err");
        setPageNotFound(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const steps = [
    "Start Here",
    // 'Enter Info',
    // 'Confirm Info',
    "Completed",
  ];
  const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: state?.user?.themeColor ?? "#784af4",
    }),
    "& .QontoStepIcon-completedIcon": {
      color: state?.user?.themeColor ?? "#784af4",
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
  }));
  useEffect(() => {
    if (progress === 100 && component !== "Information") {
      setActiveStep(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);
  useEffect(() => {
    if (component === "PrequalifiedOffers") {
      setActiveStep(3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [component]);
  useEffect(() => {
    if (component === "Information") {
      setActiveStep(4);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [component]);
  function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }
  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      background: "#eaeaf0",
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: state?.user?.themeColor ?? "rgba(0, 148, 255, 1)",
        width: activeStep === 1 ? `${progress}%` : "100%",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: state?.user?.themeColor ?? "rgba(0, 148, 255, 1)",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

  return (
    <>
      {pageNotFound ? (
        <Missing />
      ) : showLoader ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <Spinner style={{ width: "4rem", height: "4rem" }} />
        </div>
      ) : (
        state?.informationSlug !== "" && (
          <div className="container main-square-box">
            <div className="row main-square-box-height  d-flex justify-content-center">
              <div className="col-lg-10 color shadow p-3 rounded-4 pt-5">
                <div className="step-width">
                  <Stack lg={{ width: "100%" }} spacing={4}>
                    <Stepper
                      alternativeLabel
                      activeStep={activeStep}
                      connector={<QontoConnector />}
                    >
                      {steps.map((label, index) => (
                        <Step key={label}>
                          <StepLabel StepIconComponent={QontoStepIcon}>
                            {index === 0 ? "Start Here" : "Completed"}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Stack>
                </div>
                {component === "GetStarted" ||
                component === "PrequalifiedOffers" ||
                component === "Confirmation" ? (
                  <Component
                    {...{ component, setComponent, setProgress, progress }}
                  />
                ) : (
                  <div className="questions-center-div">
                    <div className="component-div">
                      <div className="left d-flex flex-column align-items-start justify-content-center">
                        <div className="inner-material">
                          {referrerUrl ? (
                            <>
                              <img
                                src={
                                  !state?.user?.logo && state?.user?.logo !== ""
                                    ? img1
                                    : state?.user?.logo
                                }
                                className="rounded-4 logo-small"
                                alt="logo"
                              />
                              {referrerEquipmentImageUrl && (
                                <img
                                  src={referrerEquipmentImageUrl}
                                  className="rounded-4 equipment-logo"
                                  alt="equipment"
                                />
                              )}
                              {referrerEquipmentDescription && (
                                <p className="mt-2 text-muted text-nowrap">
                                  {referrerEquipmentDescription}
                                </p>
                              )}
                            </>
                          ) : (
                            <img
                              src={
                                !state?.user?.logo && state?.user?.logo !== ""
                                  ? img1
                                  : state?.user?.logo
                              }
                              className="rounded-4 logo-full"
                              id="logo"
                              alt="logo"
                            />
                          )}
                        </div>
                      </div>
                      <div className="right input-field-center">
                        <Component
                          {...{
                            component,
                            setComponent,
                            setProgress,
                            progress,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className="text-end me-3 mt-3 call-us"
                  style={{ color: "rgba(134, 138, 139, 1)" }}
                >
                  Got Questions, Call us{" "}
                  <a style={{ textDecoration: "None" }} href="tel:+18887014622">
                    +1-888-701-4622
                  </a>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
}

export default Question;
