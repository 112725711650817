import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../styles/dashboard.css';
import img1 from '../../../Images/logo.webp';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { AiOutlineBell } from 'react-icons/ai';
import { TbSettings2 } from 'react-icons/tb';
import ProfileDropdown from './profileaccount';
import { useContext } from 'react';
import { GlobalContext } from '../../../context/GlobalContext';
import QueryString from 'query-string';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import useWindowWidth from './useWindowWidth';

const Sidebar = () => {
    const navigate2 = useNavigate();
    const location = useLocation();
    const currentUrl = QueryString.parseUrl(location?.pathname)?.url;
    const urlParts = currentUrl.split('/');
    const pathname = decodeURIComponent(urlParts[1]);
    const pathname2 = decodeURIComponent(urlParts[2]);
    const { state, dispatch } = useContext(GlobalContext);
    const image = state?.user?.profilePicture;
    const role = state?.user?.role;
    const windowWidth = useWindowWidth();

    useEffect(() => {
        const isLoggedIn = window.localStorage.getItem("isLoggedIn");
        if (isLoggedIn !== "false" && isLoggedIn !== false) {
            dispatch({
                type: "IS_LOGGED_IN",
                payload: isLoggedIn
            });
        } else {
            navigate2('/login');
        }
    }, [state?.isLoggedIn]);

    const iconsNav = (
        <Nav className={`D-icons ms-auto me-4 ${windowWidth < 992 ? 'd-flex flex-row mobilePadding' : ''}`}>
            <AiOutlineBell className='icon1 hovery-effect-nav-icons'></AiOutlineBell>
            <TbSettings2 className='icon2 hovery-effect-nav-icons'></TbSettings2>
            <div className="app">
                <ProfileDropdown />
            </div>
        </Nav>
    );

    return (
        <>
            <Navbar expand="lg" className='bg-white'>
                <Container fluid>
                    <img src={state?.user?.logo !== "" ? state?.user?.logo : img1} className='nav-logo mx-3 rounded-4' alt="logo" />
                    {windowWidth < 992 && iconsNav}
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link className={pathname === "" && "active"} onClick={() => navigate2('/')}>Dashboard</Nav.Link>
                            <Nav.Link className={pathname === "profile" && "active"} onClick={() => navigate2("/profile")}>Profile</Nav.Link>
                            <Nav.Link className={pathname2?.includes("not-completed") || pathname2?.includes("Not Completed") ? "active" : ""} onClick={() => navigate2("/applications/not-completed?page_no=1")}>Not Completed</Nav.Link>
                            <Nav.Link className={pathname2?.includes("in-review") || pathname2?.includes("In Review") ? "active" : ""} onClick={() => navigate2("/applications/in-review?page_no=1")}>In Review</Nav.Link>
                            <Nav.Link className={pathname2?.includes("approved") || pathname2?.includes("Approved") ? "active" : ""} onClick={() => navigate2("/applications/approved?page_no=1")}>Approved</Nav.Link>
                            <Nav.Link className={pathname2?.includes("contract-out") || pathname2?.includes("Contracts Out") ? "active" : ""} onClick={() => navigate2("/applications/contract-out?page_no=1")}>Contracts Out</Nav.Link>
                            <Nav.Link className={pathname2?.includes("contract-in") || pathname2?.includes("Contracts In") ? "active" : ""} onClick={() => navigate2("/applications/contract-in?page_no=1")}>Contracts In</Nav.Link>
                            <Nav.Link className={pathname2?.includes("funded") || pathname2?.includes("Funded") ? "active" : ""} onClick={() => navigate2("/applications/funded?page_no=1")}>Funded</Nav.Link>
                            <Nav.Link className={pathname2?.includes("archived") || pathname2?.includes("Archived") ? "active" : ""} onClick={() => navigate2("/applications/archived?page_no=1")}>Archived</Nav.Link>
                            <Nav.Link className={pathname === "application" && "active"} onClick={() => navigate2("/application")}>Application Links</Nav.Link>
                            {role?.toLowerCase() === 'admin' && <Nav.Link className={pathname === "users" && "active"} onClick={() => navigate2("/users")}>Users</Nav.Link>}
                        </Nav>
                        {windowWidth >= 992 && iconsNav}
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}

export default Sidebar;
