import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import Cookies from "universal-cookie";
import "../../styles/dashboard.css";
import { Button, Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useLocation, useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import NavDropdown from "react-bootstrap/NavDropdown";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { CiSearch } from "react-icons/ci";
import axios from "../../../api/axios";

const cookies = new Cookies();

const DataTable = ({ data, status, setRefreshData }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state, dispatch } = useContext(GlobalContext);
  const isDashboard = location.pathname === "/" ? true : false;
  const isNotcompleted =
    location.pathname === "/applications/not-completed" ? true : false;
  const isArchived =
    location.pathname === "/applications/archived" ? true : false;
  const [selectedApplication, setSelectedApplication] = useState("");

  const [openReferModal, openOpenReferModal] = React.useState(false);
  const [openArchiveModal, setOpenArchiveModal] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [companyUsers, setCompanyUsers] = useState([]);

  const [showAlert, setShowAlert] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  const handleCloseReferModal = () => {
    openOpenReferModal(false);
  };

  const handleCloseArchiveModal = () => {
    setOpenArchiveModal(false);
  };

  const handleCloseErrorSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleCloseSuccessSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen2(false);
  };

  const getCompanyUsers = async () => {
    try {
      const response = await axios.get(
        `lender/list/company-users/?search=${searchString}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${cookies.get("access")}`,
          },
          withCredentials: true,
        }
      );
      setCompanyUsers(response?.data);
    } catch (error) {
      setCompanyUsers([]);
    }
  };

  useEffect(() => {
    getCompanyUsers();
  }, [searchString]);

  const handleSelectReferedUser = async (user) => {
    try {
      const response = await axios.put(
        "lender/refer/application/",
        {
          referring_user_id: user,
          application_uuid: selectedApplication,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${cookies.get("access")}`,
          },
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        setSelectedApplication("");
        handleCloseReferModal();
        setOpen2(true);
        setShowAlert("Referrer user assigned successfully");
        setRefreshData((state) => !state);
      }
    } catch (error) {
      setOpenSnackbar(true);
      setErrMsg("Server error");
    }
  };

  const handleRemoveReferredUser = async (applicationUUID) => {
    try {
      const response = await axios.delete(
        `lender/refer/application/?application_uuid=${applicationUUID}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${cookies.get("access")}`,
          },
          withCredentials: true,
        }
      );
      if (response.status === 204) {
        setOpen2(true);
        setShowAlert("Referrer user removed successfully");
        setRefreshData((state) => !state);
      }
    } catch (error) {
      setOpenSnackbar(true);
      setErrMsg("Server error");
    }
  };

  const handleChangeApplicationStatus = async () => {
    try {
      const response = await axios.put(
        `lender/change/application-status/`,
        { application_uuid: selectedApplication, status: "Archived" },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${cookies.get("access")}`,
          },
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        setSelectedApplication("");
        handleCloseArchiveModal();
        setOpen2(true);
        setShowAlert("Application archived");
        setRefreshData((state) => !state);
      }
    } catch (error) {
      setOpenSnackbar(true);
      setErrMsg("Server error");
    }
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (openReferModal) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openReferModal]);

  function formatDate(date) {
    const pdtDate = new Date(date);
    const localTimezoneOffset = pdtDate.getTimezoneOffset() / -60;
    const localTimezoneAbbr = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localDate = new Date(pdtDate);
    localDate.setHours(localDate.getHours() + localTimezoneOffset);
    return localDate.toLocaleString("en-US", { timeZone: localTimezoneAbbr });
  }
  return (
    <>
      {errMsg && (
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseErrorSnackbar}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Alert
            onClose={handleCloseErrorSnackbar}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {errMsg}
          </Alert>
        </Snackbar>
      )}
      {showAlert && (
        <Snackbar
          open={open2}
          autoHideDuration={6000}
          onClose={handleCloseSuccessSnackbar}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Alert
            onClose={handleCloseSuccessSnackbar}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {showAlert}
          </Alert>
        </Snackbar>
      )}
      <div className="refer-model-div">
        <Dialog
          open={openReferModal}
          onClose={handleCloseReferModal}
          scroll={"body"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogContent>
            <div className="ms-auto">
              <div className="icon-field">
                <CiSearch className="ms-2 icon-search" />
                <input
                  className="search-here input-filed search-bar-field"
                  type="text"
                  name={searchString}
                  placeholder="Search here..."
                  value={searchString}
                  onChange={(e) => {
                    setSearchString(e.target.value);
                  }}
                />
              </div>
            </div>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <ul className="mt-3">
                {companyUsers.map((user) => (
                  <li
                    key={user.id}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: "4px",
                    }}
                  >
                    <span style={{ marginRight: "10px" }}>{user.username}</span>
                    <Button
                      variant="primary"
                      size="small"
                      onClick={() => handleSelectReferedUser(user.id)}
                    >
                      Select
                    </Button>
                  </li>
                ))}
              </ul>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
      <div className="archive-model-div">
        <Modal show={openArchiveModal} onHide={handleCloseArchiveModal}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Move</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to move application to archive?
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              className="btn btn-light d-flex align-items-center justify-content-center ms-2"
              style={{ width: "73.99px" }}
              onClick={handleCloseArchiveModal}
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              type="submit"
              className="btn btn-danger d-flex align-items-center justify-content-center ms-2"
              style={{ width: "73.99px" }}
              onClick={handleChangeApplicationStatus}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <Table className="table-backgorund">
        <thead className="table-head">
          <tr>
            <th className="table-width">Created Time</th>
            {state?.user?.role !== "Individual" && (
              <th lassName="table-width">Created By</th>
            )}
            {!isNotcompleted && <th lassName="table-width">Refered To</th>}
            <th>Equipment Name</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th className="table-phone-width">Phone</th>
            <th className="table-width">Email</th>
            <th>Equipment Price</th>
            {isDashboard && <th>Status</th>}
            <th>Link ID</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item) => (
            <>
              <div style={{ marginBottom: "0.5rem" }}></div>
              <tr style={{ cursor: "pointer" }}>
                <td onClick={() => navigate(`/applications/${item?.uuid}`)}>
                  {formatDate(item?.created_at)}
                </td>
                {state?.user?.role !== "Individual" && (
                  <td onClick={() => navigate(`/applications/${item?.uuid}`)}>
                    {item?.created_by}
                  </td>
                )}
                {!isNotcompleted && (
                  <td onClick={() => navigate(`/applications/${item?.uuid}`)}>
                    {item?.referred_to}
                  </td>
                )}
                <td onClick={() => navigate(`/applications/${item?.uuid}`)}>
                  {item?.equipment_name}
                </td>
                <td onClick={() => navigate(`/applications/${item?.uuid}`)}>
                  {item?.first_name}
                </td>
                <td onClick={() => navigate(`/applications/${item?.uuid}`)}>
                  {item?.last_name}
                </td>
                <td onClick={() => navigate(`/applications/${item?.uuid}`)}>
                  {item?.phone_no}
                </td>
                <td onClick={() => navigate(`/applications/${item?.uuid}`)}>
                  {item?.email}
                </td>
                <td onClick={() => navigate(`/applications/${item?.uuid}`)}>
                  {item?.equipment_price}
                </td>
                {isDashboard && (
                  <td onClick={() => navigate(`/applications/${item?.uuid}`)}>
                    {item?.status}
                  </td>
                )}
                <td
                  className="link-click"
                  onClick={() => navigate(`/applications/${item?.uuid}`)}
                >
                  {item?.link}
                </td>
                <td>
                  <button className="action-btn">
                    <NavDropdown
                      title={<span className="p-1 link-click-II">Action</span>}
                      id="nav-dropdown"
                    >
                      <NavDropdown.Item>
                        <span
                          className="p-1  font-show"
                          onClick={() =>
                            navigate(`/applications/${item?.uuid}`)
                          }
                        >
                          Show deal
                        </span>
                      </NavDropdown.Item>
                      <NavDropdown.Item>
                        <span
                          className="p-1  font-show"
                          onClick={() =>
                            navigate(`/applications/${item?.uuid}/offers`)
                          }
                        >
                          Show offers
                        </span>
                      </NavDropdown.Item>
                      {!isNotcompleted && (
                        <NavDropdown.Item>
                          {item?.referred_to ? (
                            <span
                              className="p-1 font-show"
                              onClick={() => {
                                handleRemoveReferredUser(item?.uuid);
                              }}
                            >
                              Remove Referrer
                            </span>
                          ) : (
                            <span
                              className="p-1 font-show"
                              onClick={() => {
                                setSelectedApplication(item?.uuid);
                                openOpenReferModal(true);
                              }}
                            >
                              Refer User
                            </span>
                          )}
                        </NavDropdown.Item>
                      )}
                      {!isArchived && (
                        <NavDropdown.Item>
                          <span
                            className="p-1  font-show"
                            onClick={() => {
                              setSelectedApplication(item?.uuid);
                              setOpenArchiveModal(true);
                            }}
                          >
                            Archive app
                          </span>
                        </NavDropdown.Item>
                      )}
                    </NavDropdown>
                  </button>
                </td>
              </tr>
            </>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default DataTable;
