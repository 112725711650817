import React, { useContext, useEffect, useState } from 'react'
import '../../styles/dashboard.css';
import { Form } from 'react-bootstrap';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import axios, { axiosPrivate } from '../../../api/axios';
import Cookies from 'universal-cookie';
import RegisterYourCompanyModal from '../../../utils/modals/RegisterYourCompanyModal';
import { GlobalContext } from '../../../context/GlobalContext';
import ColorPicker from '../../../utils/ColorPicker';
import { getBaseStyle, getHoverStyle } from '../../../utils/styles';
import EditProfileModal from '../../../utils/modals/EditProfileModal';
import EditCompanyModal from '../../../utils/modals/EditCompanyModal';
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import LoadingBar from 'react-top-loading-bar'
import Spinner from 'react-bootstrap/Spinner';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { deepOrange, deepPurple } from '@mui/material/colors';

const cookies = new Cookies();
const Profile = () => {
    const [progress, setProgress] = useState(30);
    const [showLoader] = useState(false);
    const [showLoader2] = useState(false);
    const { state, dispatch } = useContext(GlobalContext)
    const role = state.user.role;
    const [showAlert, setShowAlert] = useState('')
    const [showAlert2, setShowAlert2] = useState('')
    const [imageURL, setImageURL] = useState()
    const [imageFile, setImageFile] = useState('');
    const [imageFile2, setImageFile2] = useState('');
    const options = ['Construction', 'Ag', 'Transpo', 'Lifts'];
    const [imageURL2, setImageURL2] = useState('');
    const companyExist = role?.toLowerCase() === "admin"
    const [showModal, setShowModal] = useState(false)
    const [errMsg, setErrMsg] = useState('');
    const [errMsg2, setErrMsg2] = useState('');
    const [isHovered, setIsHovered] = useState(false);
    const [color, setColor] = useState(state?.user?.themeColor ?? "#ffffff");
    const [buttonHover, setButtonHover] = useState(false)
    const [validated, setValidated] = useState(false);
    const baseColor = state?.user?.themeColor;
    const baseStyle = getBaseStyle(baseColor);
    const hoverStyle = getHoverStyle(baseColor);
    const [showEditProfileModal, setShowEditProfileModal] = useState(false)
    const [showEditCompanyModal, setShowEditCompanyModal] = useState(false)
    const [formData, setFormData] = useState({
        username: state.user.username ?? "",
        first_name: state.user.firstName ?? "",
        last_name: state.user.lastName ?? "",
        email: state.user.email ?? "",
        phone_no: formatNumber(state.user.phoneNo) ?? '',
        profile_picture: state.user.profilePicture ?? null,
        company_name: state.user.companyName ?? "",
        address: state.user.address ?? "",
        is_show_offers: state.user.isShowOffers ?? false,
    });

    const [companyData, setCompanyData] = useState({
        name: "",
        email: "",
        contact_number: "",
        address: "",
        referring_vendor_account: "",
        logo: "",
        theme_color: "",
        is_active: false,
        equipment_category: ""
    })
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const handleClose2 = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
        setOpen2(false)
    };
    useEffect(() => {
        axiosPrivate.get("auth/user/profile/", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${cookies.get("access")}`,
            },
            withCredentials: true,
        }).then((response) => {
            const responseData = response.data;
            setFormData({
                username: responseData?.username ?? "",
                first_name: responseData?.first_name ?? "",
                last_name: responseData?.last_name ?? "",
                email: responseData?.email ?? "",
                phone_no: formatNumber(responseData?.phone_no) ?? "",
                profile_picture: responseData?.profile_picture ?? "",
                is_show_offers: responseData?.is_show_offers ?? false,
                company_name: responseData?.company_name ?? "",
                address: responseData?.address ?? ""
            });
            setImageURL(responseData?.profile_picture);
            setProgress(100)
        })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, [])
    useEffect(() => {
        setCompanyData((prevFormData) => ({
            ...prevFormData,
            theme_color: color,
        }))
    }, [color])
    useEffect(() => {
        if (showModal === false && showEditProfileModal === false) {
            dispatch({
                type: "REFRESH_PROFILE",
                payload: !state.refreshProfile
            })
        }
    }, [showModal, showEditProfileModal])
    useEffect(() => {
        if (state.user.role?.toLowerCase() === "admin" && !showEditCompanyModal) {
            axiosPrivate.get("lender/admin/list/company-details/", {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${cookies.get("access")}`,
                },
                withCredentials: true,
            }).then((response) => {
                const responseData = response.data;
                setCompanyData({
                    name: responseData?.name ?? "",
                    email: responseData?.email ?? "",
                    contact_number: formatNumber(responseData?.contact_number) ?? "",
                    address: responseData?.address ?? "",
                    referring_vendor_account: responseData?.referring_vendor_account ?? "",
                    theme_color: responseData?.theme_color,
                    logo: responseData?.logo ?? "",
                    is_active: responseData?.is_active ?? false,
                    equipment_category: responseData?.equipment_category
                });
                setProgress(100)
                setImageURL2(responseData?.logo)
                setColor(responseData?.theme_color)
            })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                    setProgress(100)

                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showEditCompanyModal, state.user.role]);
    function formatNumber(sanitizedValue) {
        let formatted = '';
        for (let i = 0; i < sanitizedValue?.length; i++) {
            if (i === 2 || i === 5 || i === 8) {
                formatted += `-${sanitizedValue[i]}`;
            } else {
                formatted += sanitizedValue[i];
            }
        }
        return formatted;
    }
    const HandlePhoneChange = (e) => {
        const value = e.target.value;

        const sanitizedValue = value.replace(/[^A-Za-z0-9+]/g, '');
        if (sanitizedValue?.length < 13) {
            const formatted = formatNumber(sanitizedValue)
            setFormData((prevFormData) => ({
                ...prevFormData,
                phone_no: formatted, // .replace(/-/g, '')
            }))
        }
    }
    const HandleCompanyPhoneChange = (e) => {
        const value = e.target.value;

        const sanitizedValue = value.replace(/[^A-Za-z0-9+]/g, '');
        if (sanitizedValue?.length < 13) {
            const formatted = formatNumber(sanitizedValue)
            setCompanyData((prevFormData) => ({
                ...prevFormData,
                contact_number: formatted, // .replace(/-/g, '')
            }))
        }
    }
    const handleFileClick = (event) => {
        event.target.value = null;
    };
    const handleImageChange = (e) => {
        if (e.target.value) {
            const selectedImage = e.target.files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                setImageURL(event.target.result);
            };
            reader.readAsDataURL(selectedImage);
            setImageFile(selectedImage)
        }
    };
    const handleColorChange = (event) => {
        let value = event.target.value;
        if (event.target.type === 'text') {
            if (!value.startsWith('#')) {
                value = '#' + value;
            }
            if (value.length > 7) {
                value = value.substring(0, 7);
            }
        }
        setColor(value);
    };
    const handleFileClick2 = (event) => {
        event.target.value = null;
    };
    const handleImageChange2 = (e) => {
        if (e.target.value) {
            const selectedImage = e.target.files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                setImageURL2(event.target.result);
            };
            reader.readAsDataURL(selectedImage);
            setImageFile2(selectedImage)
        }
    };
    const handleSignup = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        setErrMsg('');
        setShowAlert('');
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true)
        } else {
            setValidated(false)
            let body = { ...formData }
            body.phone_no = formData.phone_no.replace(/-/g, '')
            body.profile_picture = imageFile
            axios.put("auth/user/profile/", body, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${cookies.get("access")}`,
                },
                withCredentials: true,
            }).then(res => {
                setShowAlert(res?.data?.Success)
                setOpen2(true)

            }).catch(err => {
                if (err?.response?.data?.email) {
                    setErrMsg(err?.response?.data?.email)
                    setOpen(true)
                } else if (err?.response?.data?.name) {
                    setErrMsg(err?.response?.data?.name)
                    setOpen(true)

                } else {
                    setErrMsg("Server Error")
                    setOpen(true)

                }
            })
        }

    }
    const handleSignup2 = (e) => {
        setErrMsg2('');
        setShowAlert2('');
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true)
        } else {
            setValidated(false)
            let body = { ...companyData }
            body.contact_number = companyData.contact_number.replace(/-/g, '')
            body.logo = imageFile2
            axios.put("lender/admin/list/company-details/", body, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${cookies.get("access")}`,
                },
                withCredentials: true,
            }).then(res => {
                setShowAlert2(res?.data?.Success)
                setOpen2(true)

            }).catch(err => {
                if (err?.response?.data?.email) {
                    setErrMsg2(err?.response?.data?.email)
                    setOpen2(true)
                } else if (err?.response?.data?.name) {
                    setErrMsg2(err?.response?.data?.name)
                    setOpen2(true)

                } else if (err?.response?.data?.logo) {
                    setErrMsg2(err?.response?.data?.logo)
                    setOpen2(true)

                } else {
                    setErrMsg("Server Error")
                    setOpen(true)

                }
            })
        }

    }
    return (
        <>
            {
                errMsg && (
                    <Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose2}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                    >
                        <Alert
                            onClose={handleClose2}
                            severity="error"
                            variant="filled"
                            sx={{ width: '100%' }}
                        >
                            {errMsg}
                        </Alert>
                    </Snackbar>
                )
            }
            {
                showAlert && (
                    <Snackbar
                        open={open2}
                        autoHideDuration={6000}
                        onClose={handleClose2}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                    >
                        <Alert
                            onClose={handleClose2}
                            severity="success"
                            variant="filled"
                            sx={{ width: '100%' }}
                        >
                            {showAlert}
                        </Alert>
                    </Snackbar>
                )
            }
            <RegisterYourCompanyModal setShowModal={setShowModal} showModal={showModal} />
            {/* <EditProfileModal showModal={showEditProfileModal} setShowModal={setShowEditProfileModal} /> */}
            {/* <EditCompanyModal showModal={showEditCompanyModal} setShowModal={setShowEditCompanyModal} /> */}
            <LoadingBar
                color="rgba(0, 148, 255, 1)"
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />
            <div className='row mt-4'>
                <div className='col-lg-12 col-md-12'>
                    <div className='colorr shadow p-3 rounded-4 profile-height'>
                        <div className='mb-5'>
                            <div className='d-flex justify-content-between'>
                                <h4 className=''>Personal Information</h4>
                                {!companyExist && state?.user?.role?.toLowerCase() !== "staff" && state.user.username !== "" && <button className='btn btn-info' onClick={() => setShowModal(true)}>Register Your Company</button>}
                            </div>
                            <Form noValidate validated={validated} onSubmit={handleSignup}>
                                {/* <div className={errMsg ? "alert alert-danger alert-dismissible fade show" : "offscreen"}>
                                    {errMsg}
                                    <button type="button" className={errMsg ? "btn-close" : "d-none"} onClick={() => setErrMsg('')}></button>
                                </div>
                                <div className={showAlert ? "alert alert-info alert-dismissible fade show" : "offscreen"}>
                                    {showAlert}
                                    <button type="button" className={showAlert ? "btn-close" : "d-none"} onClick={() => setShowAlert('')}></button>
                                </div> */}
                                <div className="profile-img mb-4">
                                    {/* <img src={imageURL} alt="Profile" className="P-I" /> */}
                                    {/* <label htmlFor="profilePictureInputEdit"
                                        className={`image-container ${isHovered ? 'hovered' : ''}`}>
                                        <img
                                            src={imageURL}
                                            alt="Profile"
                                            className="P-I"
                                            onMouseEnter={() => setIsHovered(true)}
                                            onMouseLeave={() => setIsHovered(false)}
                                        />
                                        <input
                                            type="file"
                                            id="profilePictureInputEdit"
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            onChange={handleImageChange}
                                            onClick={handleFileClick}
                                        />
                                        <span className="tooltip-text">Upload Image</span>
                                    </label> */}
                                    <Stack direction="row" spacing={2}>
                                        <Avatar sx={{ bgcolor: '#0094FF', width: 100, height: 100,fontSize:'35px' }}>{(formData?.first_name && formData.last_name) ? (`${formData?.first_name.charAt(0).toUpperCase()}${formData?.last_name.charAt(0).toUpperCase()}`) : `${formData?.username.charAt(0)}`}</Avatar>
                                    </Stack>
                                </div>
                                <div className='row'>
                                    <Form.Group className="col-6 col-lg-6 col-md-6 mb-2" controlId="exampleForm.ControlInput1">
                                        <Form.Label>User Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="username"
                                            name={formData.username}
                                            value={formData.username}
                                            onChange={(e) => setFormData((prevFormData) => ({
                                                ...prevFormData,
                                                username: e.target.value,
                                            }))}
                                            pattern="^(?!.*\s).{3,}$"
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid username.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="col-6 col-lg-6 col-md-6" controlId="exampleForm.ControlInput2">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name={formData.first_name}
                                            placeholder="firstname"
                                            value={formData.first_name}
                                            onChange={(e) => setFormData((prevFormData) => ({
                                                ...prevFormData,
                                                first_name: e.target.value,
                                            }))}
                                            pattern={'^.{3,}$'}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid firstname.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className=" col-6 col-lg-6 col-md-6 mb-2" controlId="exampleForm.ControlInput3">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="lastname"
                                            name={formData.last_name}
                                            value={formData.last_name}
                                            onChange={(e) => setFormData((prevFormData) => ({
                                                ...prevFormData,
                                                last_name: e.target.value,
                                            }))}
                                            pattern={'^.{3,}$'}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid lastname.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="col-6 col-lg-6 col-md-6" controlId="exampleForm.ControlInput4">
                                        <Form.Label>Email Address</Form.Label>
                                        <Form.Control
                                            type="email"
                                            placeholder="email"
                                            value={formData.email}
                                            name={formData.email}
                                            onChange={(e) => setFormData((prevFormData) => ({
                                                ...prevFormData,
                                                email: e.target.value,
                                            }))}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid email.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="col-6 col-lg-6 col-md-6" controlId="exampleForm.ControlInput7">
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Phone Number with (+1)"
                                            name={formData.phone_no}
                                            value={formData.phone_no}
                                            onChange={HandlePhoneChange}
                                            pattern="\+1-\d{3}-\d{3}-\d{4}"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Valid US phone number e.g.(+1-123-456-7890).
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            label="Show offers"
                                            //  value={formData.is_show_offers}
                                            checked={formData.is_show_offers}
                                            className='my-2'
                                            onChange={(e) => setFormData((prevFormData) => ({
                                                ...prevFormData,
                                                is_show_offers: e.target.checked,
                                            }))}
                                        />
                                    </FormGroup>

                                </div>
                                <div className='btn-mob-view'>
                                    {/* <button
                                        type="button"
                                        className="btn btn-primary mt-3 profile-btn"
                                        onClick={() => setShowEditProfileModal(true)}
                                        onMouseEnter={() => setButtonHover(true)}
                                        onMouseLeave={() => setButtonHover(false)}
                                        style={state?.user?.role !== "Staff" ? { backgroundColor: "rgba(0, 148, 255, 1)" } : buttonHover ? hoverStyle : baseStyle}
                                    >Edit</button> */}
                                    <button className="btn btn-dark  px-5" type="submit">
                                        {showLoader ? <Spinner style={{ width: "1.4rem", height: "1.4rem" }} /> : "Save"}</button>
                                </div>
                            </Form>
                        </div>
                        {companyExist &&
                            <div className='mb-5'>
                                <div className={errMsg2 ? "alert alert-danger alert-dismissible fade show" : "offscreen"}>
                                    {errMsg2}
                                    <button type="button" className={errMsg2 ? "btn-close" : "d-none"} onClick={() => setErrMsg2('')}></button>
                                </div>
                                <div className={showAlert2 ? "alert alert-success alert-dismissible fade show" : "offscreen"}>
                                    {showAlert2}
                                    <button type="button" className={showAlert2 ? "btn-close" : "d-none"} onClick={() => setShowAlert2('')}></button>
                                </div>
                                <div className='mb-4'>
                                    <h4>Company Information</h4>
                                </div>
                                <Form noValidate validated={validated} className='' onSubmit={handleSignup2}>
                                    <div className="profile-img mb-4">
                                        {/* <label htmlFor="profilePictureInput2">
                                            <img src={imageURL2} alt="Logo" className="P-I" />
                                            <input
                                                type="file"
                                                id="profilePictureInput2"
                                                accept="image/*"
                                                style={{ display: 'none' }}
                                                readOnly
                                            />
                                        </label> */}
                                        <label htmlFor="logoPictureInputEdit"
                                            className={`image-container ${isHovered ? 'hovered' : ''}`}>
                                            <img
                                                src={imageURL2}
                                                alt="Profile"
                                                className="P-I"
                                                onMouseEnter={() => setIsHovered(true)}
                                                onMouseLeave={() => setIsHovered(false)}
                                            />
                                            <input
                                                type="file"
                                                id="logoPictureInputEdit"
                                                accept="image/*"
                                                style={{ display: 'none' }}
                                                onChange={handleImageChange2}
                                                onClick={handleFileClick2}
                                            />
                                            <span className="tooltip-text">Upload Image</span>
                                        </label>
                                    </div>
                                    <div className='row mb-3'>
                                        <Form.Group className="col-6 col-lg-6 col-md-6" controlId="exampleForm2.ControlInput1">
                                            <Form.Label>Company Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="name"
                                                name={companyData.name}
                                                value={companyData.name}
                                                onChange={(e) => setCompanyData((prevFormData) => ({
                                                    ...prevFormData,
                                                    name: e.target.value,
                                                }))}
                                                pattern="^.{3,}$"
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid name.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="col-6 col-lg-6 col-md-6" controlId="exampleForm2.ControlInput4">
                                            <Form.Label>Email Address</Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder="email"
                                                value={companyData.email}
                                                name={companyData.email}
                                                onChange={(e) => setCompanyData((prevFormData) => ({
                                                    ...prevFormData,
                                                    email: e.target.value,
                                                }))}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid email.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className='row mb-3'>
                                        <Form.Group className="col-6 col-lg-6 col-md-6 mb-2" controlId="exampleForm2.ControlInput9">
                                            <Form.Label>Address</Form.Label>
                                            <Form.Control
                                                placeholder="Enter address"
                                                type="text"
                                                autoComplete="off"
                                                className="form-control"
                                                value={companyData.address}
                                                name={companyData.address}
                                                onChange={(e) => setCompanyData((prevFormData) => ({
                                                    ...prevFormData,
                                                    address: e.target.value,
                                                }))}
                                                aria-describedby="uidnote"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid address name.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="col-6 col-lg-6 col-md-6" controlId="exampleForm2.ControlInput7">
                                            <Form.Label>Phone Number</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Phone Number with (+1)"
                                                name={companyData.contact_number}
                                                value={companyData.contact_number}
                                                onChange={HandleCompanyPhoneChange}
                                                pattern="\+1-\d{3}-\d{3}-\d{4}"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Valid US phone number e.g.(+1-123-456-7890).
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="col-6 col-lg-6 col-md-6" controlId="exampleForm2.ControlInput1">
                                            <Form.Label>Vendor ID</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Id"
                                                name={companyData.referring_vendor_account}
                                                value={companyData.referring_vendor_account}
                                                onChange={(e) => setCompanyData((prevFormData) => ({
                                                    ...prevFormData,
                                                    referring_vendor_account: e.target.value,
                                                }))}
                                                pattern="^.{3,}$"
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Id.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className='col-6 col-lg-6 col-md-6 mt-2' controlId='exampleForm2.ControlInput8'>
                                            <Form.Label>Theme Color</Form.Label>
                                            <ColorPicker color={color} setColor={setColor} edit2={true} required={true} />
                                            <Form.Control
                                                type="text"
                                                style={{ display: 'none' }}
                                                value={color}
                                                onChange={handleColorChange}
                                                required
                                                pattern="^#([0-9a-fA-F]{6})$"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid hex code.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className=" col-6 col-lg-6 col-md-6 mt-2" controlId="exampleForm2.ControlInput3">
                                            <Form.Label>Equipment Category</Form.Label>
                                            <Form.Control as="select" type="select" name="payment_method"
                                                onChange={(e) => setCompanyData((prevFormData) => ({
                                                    ...prevFormData,
                                                    equipment_category: e.target.value,
                                                }))}
                                                value={companyData.equipment_category}>
                                                <option value="">Select an option</option>
                                                {options.map((option, index) => (
                                                    <option key={index} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid lastname.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>

                                    <div>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Checkbox />}
                                                label="Activate"
                                                className='my-2'
                                                //  value={formData.is_show_offers}
                                                checked={companyData.is_active}
                                                onChange={(e) => setCompanyData((prevFormData) => ({
                                                    ...prevFormData,
                                                    is_active: e.target.checked,
                                                }))}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className='btn-mob-view mt-2'>
                                        {/* <button
                                            type="button"
                                            className="btn btn-primary mt-3 profile-btn"
                                            onClick={() => setShowEditCompanyModal(true)}
                                            style={{ backgroundColor: "rgba(0, 148, 255, 1)" }}
                                        >Edit
                                        </button> */}
                                        <button className="btn btn-dark  px-5" type="submit">
                                            {showLoader2 ? <Spinner style={{ width: "1.4rem", height: "1.4rem" }} /> : "Save"}</button>
                                    </div>
                                </Form>
                            </div>}
                    </div>
                </div >
            </div >
        </>
    )
}

export default Profile