import React, { useState } from 'react'
import '../../styles/dashboard.css';
import { Table } from 'react-bootstrap';
import { LuLink, LuSend } from 'react-icons/lu';
import SendApplicationLinkModal from '../../../utils/modals/SendApplicationLinkModal';
import { useContext } from 'react';
import { GlobalContext } from '../../../context/GlobalContext';
import tinycolor from 'tinycolor2';
const ApplicationData = ({ data2 }) => {
    const [isCopied, setIsCopied] = useState(false);
    const [copiedLink, setCopiedLink] = useState('')
    const [showModal, setShowModal] = useState();
    const { state } = useContext(GlobalContext);
    const role = state.user.role;
    const companyExist = role?.toLowerCase() === "admin"
    const [applicationLink, setApplicationLink] = useState('')
    const [hover, setHover] = useState(false)
    const baseColor = useState(state?.user?.themeColor);
    const baseStyle = {
        backgroundColor: baseColor,
        color: tinycolor(baseColor).isLight() ? '#000' : '#fff',
        border: `1px solid ${tinycolor(baseColor).darken(10).toString()}`,
        padding: '10px 20px',
        cursor: 'pointer',
        outline: 'none',
        fontSize: '16px',
    };

    const hoverStyle = {
        ...baseStyle,
        backgroundColor: tinycolor(baseColor).lighten(10).toString(),
    };

    const handleCopyClick = (link) => {
        const linkToCopy = `${process.env.REACT_APP_BASE_FRONT_END_URL}application/${link}`; // Replace with your desired link
        const textArea = document.createElement('textarea');
        textArea.value = linkToCopy;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        setIsCopied(true);
        setCopiedLink(link)

        setTimeout(() => {
            setIsCopied(false);
            setCopiedLink("")
        }, 3000);
    };
    function formatDate(date) {

        const pdtDate = new Date(date);
        const localTimezoneOffset = pdtDate.getTimezoneOffset() / -60;
        const localTimezoneAbbr = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const localDate = new Date(pdtDate);
        localDate.setHours(localDate.getHours() + localTimezoneOffset);
        return localDate.toLocaleString('en-US', { timeZone: localTimezoneAbbr })
    }

    return (
        <>

            <SendApplicationLinkModal showModal={showModal} setShowModal={setShowModal} applicationLink={applicationLink} />
            <Table className='table-backgorund'>
                <thead className='table-head'>
                    <tr>
                        <th>Created Time</th>
                        <th>Email</th>
                        <th>Phone</th>
                        {companyExist && (<th>User</th>)}
                        <th>Equipment Name</th>
                        <th>Equipment Price</th>
                        <th>Link ID</th>
                        <th className='table-phone-width'>Link</th>
                        <th className='table-width'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {data2?.map((item2, index) => (
                        <>
                            <div style={{ marginBottom: "0.5rem" }} key={index}></div>
                            <tr>
                                {/* <td>{item2?.created_at}</td> */}
                                <td>{formatDate(item2?.created_at)}</td>
                                <td>{item2.recent_send_email}</td>
                                <td>{item2?.recent_send_phone_number}</td>
                                {companyExist && (<td>{item2?.username}</td>)}
                                <td>{item2?.equipment_name}</td>
                                <td>{item2?.equipment_price?.toLocaleString()}</td>
                                <td>{item2?.link}</td>
                                <td className='link-review' onClick={() => handleCopyClick(item2?.link)}>
                                    {copiedLink === item2?.link && isCopied ?
                                        <span style={{ marginLeft: '10px', color: 'green', }}>Copied!</span> :
                                        <span style={{ cursor: "pointer" }}><LuLink /> Copy Link</span>
                                    }
                                </td>
                                <td><button type="button" className="  NotCompleted-btn" onClick={() => { setShowModal(true); setApplicationLink(item2?.link) }}><LuSend /><span className='p-1'>Send Link</span></button></td>

                            </tr>
                        </>
                    ))}


                </tbody>
            </Table>
        </>
    )
}

export default ApplicationData